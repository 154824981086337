<div class="content-wrapper container-xxl p-0">

  <div class="content-body">
    <!-- content-header component -->

    <app-content-header [contentHeader]="contentHeader">

    </app-content-header>

    <!-- Basic Alerts start -->
    <!-- <core-theme-customizer></core-theme-customizer> -->
    <section id="home-page">
      <section class="range-selection-DP">

        <div class="row">
          <div class="col-12">
            <div class="card">

              <div class="card-body">

                <!-- Range selection Date Picker -->
                <div class="card">
                  <div class="row">
                    <div class="col-12">
                      <span style="font-weight: bolder;">Fechas</span>
                      <div class="row" style="margin-left:2rem;">
                        <form class="form-inline">
                          <div class="form-group">
                            <div class="input-group w-0 ">
                              <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                                [autoClose]="'outside'" [showWeekNumbers]="false"
                                (dateSelect)="onDateSelection($event,datepicker,modalBasic)" [displayMonths]="1"
                                [dayTemplate]="t" outsideDays="visible" [startDate]="fromDate!" tabindex="-1" />
                              <ng-template #t let-date let-currentMonth="currentMonth" let-selected="selected"
                                let-focused="focused">
                                <span class="custom-day" [class.focused]="focused"
                                  [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled"
                                  [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)"
                                  (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                  {{ date.day }}


                                </span>

                              </ng-template>

                            </div>
                          </div>
                          <div class="form-group">
                            <div class="input-group">
                              <input style="display: none;" #dpFromDate class="form-control" placeholder="yyyy-mm-dd"
                                name="dpFromDate" [value]="formatter.format(fromDate)"
                                (input)="fromDate = validateInput(fromDate, dpFromDate.value)" disabled />
                              <div class="input-group fecha">
                                <input class="fecha" type="text" [value]="desdehasta" name="" id="" disabled>
                              </div>
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary feather icon-calendar"
                                  (click)="datepicker.toggle()" type="button" rippleEffect></button>

                              </div>
                            </div>
                          </div>
                          <ng-template #modalBasic let-modal>
                            <div class="modal-header">
                              <h4 class="modal-title" id="myModalLabel1"><i data-feather='arrow-left'></i></h4>

                            </div>
                            <div class="modal-body" tabindex="0" ngbAutofocus>

                              <p>
                                Seleccione la fecha de inicio y la fecha fin en el mismo calendario
                              </p>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')"
                                rippleEffect>
                                Aceptar
                              </button>
                            </div>
                          </ng-template>
                          <div class="form-group ml-sm-2" style="display: none;">
                            <div class="input-group">
                              <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"
                                [value]="formatter.format(toDate)" disabled />
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary feather " (click)="datepicker.toggle()"
                                  type="button" rippleEffect disabled></button>
                              </div>
                            </div>
                          </div>

                        </form>



                        <div class="row">
                          <div class="columnbut">
                            <button class="btn btn-outline-primary btn-sm m-2 float-left"
                              (click)="seleccionarAnterior()" rippleEffect>
                              Seleccionar Mes Anterior
                            </button>
                          </div>
                          <div class="columnbut">
                            <button class="btn btn-outline-primary btn-sm m-2 float-left"
                              (click)="seleccionarMesActual()" rippleEffect>
                              Seleccionar Mes Actual
                            </button>
                          </div>
                          <div class="columnbut">
                            <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarHoy()"
                              rippleEffect>
                              Seleccionar Hoy</button>
                          </div>

                          <div class="columnbut">

                            <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarAyer()"
                              rippleEffect>
                              Seleccionar Ayer</button>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=col-6>
                      <span style="font-weight: bolder;">Compara con:</span>
                      <select style="margin-left:1rem ;" name="select" [(ngModel)]="vista"
                        class="comp form-control form-control-sm" aria-placeholder="Compara con">
                        <option value="Ar">Año Retail</option> 
                        <option value="Ac" selected>Año Calendario</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="none ">
                  <button type="button" class="btn btn-outline-primary" rippleEffect (click)="mostrarFiltross()">
                    <span *ngIf="mostrarfiltrosi"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-chevron-down">
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg></span>
                    <span *ngIf="mostrarfiltros"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-chevron-up">
                        <polyline points="18 15 12 9 6 15"></polyline>
                      </svg></span>Filtros</button>
                </div>
              </div>

              <div class="row" style="display: inline-flex;">

                <div class="column" style="width:20% ">
                  <div class="row">
                    <span *ngIf="mostrarfiltros" style="font-weight: bolder;">Agencia</span>
                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset;padding-right: unset;">


                      <ng-select [clearSearchOnAdd]="true" [clearable]="false" (blur)="filterAge($event)"
                        [disabled]="deshabilitado" [items]="agencias" [multiple]="true" [closeOnSelect]="false"
                        [searchable]="true" bindLabel="nombreAgencia" placeholder="Seleccione Agencias"
                        [(ngModel)]="agenciaTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item.codigoAgencia"> {{item.nombreAgencia}}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>

                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset;padding-right: unset;">
                      <span *ngIf="!deshabilitado"><button *ngIf="mostrarCampo" type="button"
                          class="btn btn-flat-danger" rippleEffect (click)="limpiarFiltAgen()"
                          title="limpiar filtro">X</button></span>
                    </div>
                  </div>
                </div>
                <div class="column" style="width:20% ">
                  <div class="row">
                    <span *ngIf="mostrarfiltros" style="font-weight: bolder;">Formato</span>
                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset;padding-right: unset;">
                      <!-- <ng-select placeholder="Formato" style="border: 1px;border-radius: 5px;"
          [(ngModel)]="formatoTienda" (ngModelChange)="filterFormat($event)">
          <ng-option selected value="null">Formato</ng-option>
          <ng-option *ngFor="let item of formatos" [value]="item">{{item}}
          </ng-option>


        </ng-select> -->
                      <ng-select [clearSearchOnAdd]="true" [clearable]="false" (blur)="filterFormat($event)"
                        [items]="formatos" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                        bindLabel="item" placeholder="Seleccione formatos" [(ngModel)]="formatoTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item"> {{ item }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>

                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset;padding-right: unset;">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltFor()" title="limpiar filtro">X</button>
                    </div>
                  </div>
                </div>
                <div class="column" style="width:20% ">
                  <div class="row">
                    <span *ngIf="mostrarfiltros" style="font-weight: bolder;">Ciudad</span>
                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset;padding-right: unset;">
                      <!-- <ng-select placeholder="Ciudad" style="border: 1px;border-radius: 5px;" [(ngModel)]="ciudad"
          (ngModelChange)="filterPV($event)">
          <option value="null" selected>Ciudad</option>
          <ng-option *ngFor="let item of ciudades" [value]="item">{{item}}
          </ng-option>


        </ng-select> -->
                      <ng-select [clearable]="false" (blur)="filterPV($event)" [items]="ciudades" [multiple]="true"
                        [closeOnSelect]="false" [searchable]="true" [clearSearchOnAdd]="true" bindLabel="item"
                        placeholder="Seleccione ciudad" [(ngModel)]="ciudad">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item"> {{ item }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>

                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset;padding-right: unset;">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltCiu()" title="limpiar filtro">X</button>
                    </div>
                  </div>
                </div>
                <div class="column" style="width:40% ">
                  <div class="row">

                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset;padding-right: unset;">
                      <span style="font-weight: bolder;">Punto Venta</span>

                      <ng-select [clearSearchOnAdd]="true" [items]="tiendas" [clearable]="false" [multiple]="true"
                        [closeOnSelect]="false" [searchable]="true" bindLabel="Nombre" placeholder="Seleccione PV"
                        [(ngModel)]="codigoTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item.Codigo"> {{ item.Nombre }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>


                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset;padding-right: unset;">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltPv()" title="limpiar filtro">X</button>
                    </div>
                  </div>
                </div>
              </div>
              <button [disabled]="loading" class="btn btn-relief-primary" tabindex="4" rippleEffect
                (click)="buscarDatos()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>Consultar
              </button>

            </div>

          </div>
          
        </div>

       

      </section>
      
      <div class="row" *ngIf="estienda">
        <div class="col-sm-12">
          <div style="margin-left:1rem;margin-top: -2rem;" class="row">
            <h6 *ngFor="let item of diar" class="mt-2" style="font-size: 12px;"><b>Proyeccion Mes Actual:
                {{item.proyeccionTotal | currency : '$' : 'symbol' :'1.0-0' }}</b></h6>
          </div>
          <div style="margin-left:1rem;margin-top: -2rem;" class="row">

            <h6 *ngFor="let item of diar" class="mt-2" style="font-size: 12px;"> <b> Cumpl:
                {{item.cumplimientoProyeccion * 100 | number:'1.0-0'}}%</b></h6>
          </div>
          <div style="margin-left:1rem;margin-top: -2rem;" class="row">

            <h6 *ngFor="let item of diar" class="mt-2" style="font-size: 12px;"> <b> Crec:
                {{item.crecimientoProyeccion * 100 | number:'1.0-0'}}%</b></h6>
          </div>
        </div>
      </div>


      <div class="row sdiv">
        <div class="col-12">
          <h6 *ngFor="let item of diar" class="mt-2" style="font-size: 10px;">Ultima sincronizacion ventas:
            {{item.fechaSincronizacion}}</h6>
          <div class="card ">
            <!--/ Basic Date Picker -->
            <div class="card-header">
              <div class="col-lg-12 col-12">
                <div class="card card-company-table">
                  <div class="card-body p-0">
                    <div class="table-responsive">
                      <table class="table table-hover-animation">
                        <thead>
                          <tr>
                            <th>Ventas</th>
                            <th>Actual</th>
                            <th>Presupuesto Con Pendiente</th>
                            <th>Presupuesto Original</th>
                            <th>Anterior</th>
                            <th>Cumpl con Pendiente</th>
                            <th>Cumpl Original</th>
                            <th>Crecimiento</th>
                            <th class="sdiv">Part Actual</th>
                            <th class="sdiv" style="width: 150px;">Pendiente</th>
                            <th class="sdiv" style="width: 20px;">Unidades Actuales</th>
                            <th class="sdiv" style="width: 20px;">Transacciones Actuales</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">
                                <div class="avatar rounded">
                                  <div class="avatar-content">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round" class="feather feather-dollar-sign">
                                      <line x1="12" y1="1" x2="12" y2="23"></line>
                                      <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                    </svg>

                                  </div>
                                </div>
                                <div>
                                  <div class="font-weight-bolder">Fisico</div>
                                  <div class="font-small-2 text-muted"></div>
                                </div>
                              </div>
                            </td>


                            <td *ngFor="let item of diar">{{item.tVentasFisicas | currency : '$' : 'symbol' :'1.0-0'}}
                            </td>
                            <td *ngFor="let item of diar">{{item.presupuestoFisicoRecalculado| currency : '$' : 'symbol' :'1.0-0'}}
                            </td>
                            <td *ngFor="let item of diar">{{item.presupuestoFisico| currency : '$' : 'symbol' :'1.0-0'}}
                            </td>
                            
                            <td *ngFor="let item of diar">{{item.tVentasFisicasPasado | currency : '$' :
                              'symbol':'1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.pFisico | number:'1.0-0'}}%
                              <span *ngIf="colorPf" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flecharoja" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar">{{item.pFisicoRec | number:'1.0-0'}}%
                              <span *ngIf="colorPfr" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flecharojaR" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>

                            <td *ngFor="let item of diar">{{item.cFisico | number:'1.0-0'}}%
                              <span *ngIf="colorCf" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-up">
                                  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                  <polyline points="17 6 23 6 23 12"></polyline>
                                </svg></span>
                              <span *ngIf="flecharojacfis" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-down">
                                  <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                  <polyline points="17 18 23 18 23 12"></polyline>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" class="sdiv">{{item.partFisico | number:'1.1-1'}}%</td>
                            <td *ngFor="let item of diar" style="white-space: nowrap">{{item.pendFisico | currency : '$'
                              : 'symbol' :
                              '1.0-0'}}
                              <span *ngIf="colorPfi" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaPfi" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" class="sdiv">{{item.artFis | number:'1.0-0'}}
                            </td>
                            <td *ngFor="let item of diar" class="sdiv">
                              {{item.numeroTransaccioneFisicas | number:'1.0-0'}}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">
                                <div class="avatar rounded">
                                  <div class="avatar-content">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round" class="feather feather-monitor font-medium-3">
                                      <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                                      <line x1="8" y1="21" x2="16" y2="21"></line>
                                      <line x1="12" y1="17" x2="12" y2="21"></line>
                                    </svg>
                                  </div>
                                </div>
                                <div>
                                  <div class="font-weight-bolder">Digital</div>
                                  <div class="font-small-2 text-muted"></div>
                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.totalVentasVirtuales | currency : '$' : 'symbol' :
                              '1.0-0'}}</td>
                              <td *ngFor="let item of diar">{{item.presupuestoDigitalRecalculado | currency : '$' : 'symbol' :
                                '1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.presupuestoDigital | currency : '$' : 'symbol' :
                              '1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.totalVentasVirtualesPasado | currency : '$' :
                              'symbol' : '1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.pDigital | number:'1.0-0'}}%
                              <span *ngIf="colorPd" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaPdig" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar">{{item.pDigitalRec | number:'1.0-0'}}%
                              <span *ngIf="colorPdR" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaPdigR" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar">{{item.cDigital | number:'1.0-0'}}%
                              <span *ngIf="colorCd" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-up">
                                  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                  <polyline points="17 6 23 6 23 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojacDig" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-down">
                                  <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                  <polyline points="17 18 23 18 23 12"></polyline>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" class="sdiv">{{item.partDigital | number:'1.1-1'}}%</td>
                            <td *ngFor="let item of diar" class="sdiv" style="white-space: nowrap" [ngClass]="colorPdi">
                              {{item.pendDigital |
                              currency : '$' :
                              'symbol' : '1.0-0'}}
                              <span *ngIf="colorPdi" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaPdi" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" class="sdiv">{{item.cantidadArticulosDigitales |
                              number:'1.0-0'}}
                            </td>
                            <td *ngFor="let item of diar" class="sdiv">
                              {{item.numeroTransaccioneDigitales | number:'1.0-0'}}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">
                                <div class="avatar rounded">
                                  <div class="avatar-content">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round" class="feather feather-shopping-cart">
                                      <circle cx="9" cy="21" r="1"></circle>
                                      <circle cx="20" cy="21" r="1"></circle>
                                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                    </svg>
                                  </div>
                                </div>
                                <div>
                                  <div class="font-weight-bolder">Total</div>
                                  <div class="font-small-2 text-muted"></div>
                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.totalVentasRango | currency : '$' : 'symbol' :
                              '1.0-0'}}</td>
                              <td *ngFor="let item of diar">{{item.presupuestoTotalRecalculado | currency : '$' : 'symbol' : '1.0-0'}}
                              </td>
                            <td *ngFor="let item of diar">{{item.totalPpto | currency : '$' : 'symbol' : '1.0-0'}}
                            </td>
                            <td *ngFor="let item of diar">{{item.totalVentasRangoPasado | currency : '$' : 'symbol'
                              : '1.0-0'}}</td>
                            <td *ngFor="let item of diar" [ngClass]="colorPt">{{item.pTotal | number:'1.0-0'}}%
                              <span *ngIf="colorPt" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flecharojaPt" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" [ngClass]="colorPt">{{item.pTotalRec | number:'1.0-0'}}%
                              <span *ngIf="colorPtRec" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flecharojaPtRec" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" [ngClass]="colorCt">{{item.cTotal | number:'1.0-0'}}%
                              <span *ngIf="colorCumplimTotal" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-up">
                                  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                  <polyline points="17 6 23 6 23 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaCumplimTotal" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-down">
                                  <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                  <polyline points="17 18 23 18 23 12"></polyline>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" class="sdiv">100%</td>
                            <td *ngFor="let item of diar" style="white-space: nowrap" class="sdiv"
                              [ngClass]="colorPtot">{{item.pendTotal | currency
                              : '$' :
                              'symbol' : '1.0-0'}}

                              <span *ngIf="colorPtot" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flecaRojaPtot" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" class="sdiv">{{item.tArt | number:'1.0-0'}}
                            </td>
                            <td *ngFor="let item of diar" class="sdiv">
                              {{item.numeroTransaccionesMes | number:'1.0-0'}}
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-flat-success" (click)="enviarexceventas()" rippleEffect><i
                  data-feather='file-text'></i>Excel</button>
            </div>
          </div>
          <h6 *ngFor="let item of diar" class="mt-2" style="font-size: 10px;">Ultima sincronizacion Conteo:
            {{item.fechaSincronizacionCam }}</h6>
        </div>
      </div>

<!-- EMPIEZAN LAS CARDS MOBILE -->
      <div class="row ndiv">
        <h6 *ngFor="let item of diar" class="mt-2" style="font-size: 10px;">Ultima sincronizacion ventas:
          {{item.fechaSincronizacion}}</h6>
      </div>
      <div class="row ndiv">
        <h6 *ngFor="let item of diar" class="mt-2" style="font-size: 10px;">Ultima sincronizacion Conteo:
          {{item.fechaSincronizacionCam }}</h6>
      </div>  
      <div class="row ndiv">
        
        <div class=" card col-lg-6 col-12 mb-1 mb-lg-0">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-outline-primary" rippleEffect style="font-size: 0.8rem;"
              (click)="mostrarVentass()">VENTAS</button><button type="button" class="btn btn-outline-primary"
              rippleEffect style="font-size: 0.8rem;" (click)="mostrarIndicadoress()">INDICADORES</button>
              <!-- <button
              type="button" class="btn btn-outline-primary" rippleEffect style="font-size: 0.8rem;"
              (click)="mostrarPotencialess()">POTENCIALES</button> -->
          </div>
        </div>
      </div>
      <div class="row" *ngIf="mostrarVentas">

        <div class="col-12">

          <div class="card " style="background-color:#804ef5">
            <div class="card-header">
              <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                VENTA FISICA ACTUAL:
              </div>
            </div>

            <div class="divider my-2">
              <div class="divider-text"></div>
            </div>
            <div *ngFor="let item of diar" class="card-body"
              style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
              {{item.tVentasFisicas | currency : '$' : 'symbol' :'1.0-0'}}
            </div>

            <button type="button" class="btn btn-relief-primary" (click)=mostrarDetalleVentasFisico() rippleEffect>
              <span *ngIf="mostrarDetalleVentasFisicos"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-minus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>VER MENOS</span>
              <span *ngIf="!mostrarDetalleVentasFisicos"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-plus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg> VER MAS</span>
            </button>
          </div>
          <div class="card" *ngIf="mostrarDetalleVentasFisicos">
            <table class="table table-hover-animation">
              <tbody>
                <tr>
                  <th style="margin: 20%;">
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PRESUPUESTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.presupuestoFisico| currency : '$' : 'symbol' :'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    AÑO ANTERIOR
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.tVentasFisicasPasado | currency : '$' :
                    'symbol':'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CUMPLIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.pFisico | number:'1.0-0'}}% <span *ngIf="colorPf"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg></span>
                    <span *ngIf="flecharoja" style="color: rgb(158, 47, 47);"><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CRECIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cFisico | number:'1.0-0'}}% <span *ngIf="colorCf"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-trending-up">
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                        <polyline points="17 6 23 6 23 12"></polyline>
                      </svg></span>
                    <span *ngIf="flecharojacfis" style="color: rgb(158, 47, 47);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-trending-down">
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                        <polyline points="17 18 23 18 23 12"></polyline>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PART ACTUAL
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.partFisico | number:'1.0-0'}}%</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>

                    PENDIENTE
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.pendFisico | currency : '$' : 'symbol' :
                    '1.0-0'}}<span *ngIf="colorPfi" style="color: rgb(49, 170, 79);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaPfi" style="color: rgb(158, 47, 47);"><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>

                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-shopping-bag">
                          <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                          <line x1="3" y1="6" x2="21" y2="6"></line>
                          <path d="M16 10a4 4 0 0 1-8 0"></path>
                        </svg>
                      </div>
                    </div>
                    UND FISICAS
                  </th>

                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.artFis | number:'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-credit-card">
                          <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                          <line x1="1" y1="10" x2="23" y2="10"></line>
                        </svg>

                      </div>
                    </div>
                    TRANS FISICAS
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.numeroTransaccioneFisicas | number:'1.0-0'}}</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card " style="background-color:#804ef5">
            <div class="card-header">
              <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                VENTA DIGITAL ACTUAL:
              </div>
            </div>
            <div class="divider my-2">
              <div class="divider-text"></div>
            </div>
            <div *ngFor="let item of diar" class="card-body"
              style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
              {{item.totalVentasVirtuales | currency : '$' : 'symbol' :'1.0-0'}}
            </div>
            <button type="button" class="btn btn-relief-primary" (click)=mostrarDetalleVentasDigitales() rippleEffect>
              <span *ngIf="mostrarDetalleVentasDigitaless"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                  height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" class="feather feather-minus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>VER MENOS</span>
              <span *ngIf="!mostrarDetalleVentasDigitaless"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                  height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg> VER MAS</span>
            </button>
          </div>
          <div class="card" *ngIf="mostrarDetalleVentasDigitaless">
            <table class="table table-hover-animation">
              <tbody>
                <tr>
                  <th style="margin: 20%;">
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PRESUPUESTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.presupuestoDigital| currency : '$' : 'symbol' :'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    AÑO ANTERIOR
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.totalVentasVirtualesPasado | currency : '$' :
                    'symbol':'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CUMPLIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.pDigital | number:'1.0-0'}}%<span *ngIf="colorPd"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaPdig" style="color: rgb(158, 47, 47);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CRECIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cDigital | number:'1.0-0'}}%<span *ngIf="colorCd"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-trending-up">
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                        <polyline points="17 6 23 6 23 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojacDig" style="color: rgb(158, 47, 47);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-trending-down">
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                        <polyline points="17 18 23 18 23 12"></polyline>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PART ACTUAL
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.partDigital | number:'1.0-0'}}%</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>

                    PENDIENTE
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.pendDigital | currency : '$' : 'symbol' :
                    '1.0-0'}}<span *ngIf="colorPdi" style="color: rgb(49, 170, 79);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaPdi" style="color: rgb(158, 47, 47);"><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>

                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-shopping-bag">
                          <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                          <line x1="3" y1="6" x2="21" y2="6"></line>
                          <path d="M16 10a4 4 0 0 1-8 0"></path>
                        </svg>
                      </div>
                    </div>
                    UND DIGITALES
                  </th>

                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cantidadArticulosDigitales | number:'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-credit-card">
                          <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                          <line x1="1" y1="10" x2="23" y2="10"></line>
                        </svg>

                      </div>
                    </div>
                    TRANS DIGITALES
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.numeroTransaccioneDigitales | number:'1.0-0'}}</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card " style="background-color:#804ef5">
            <div class="card-header">
              <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                VENTA TOTAL ACTUAL:
              </div>
            </div>
            <div class="divider my-2">
              <div class="divider-text"></div>
            </div>
            <div *ngFor="let item of diar" class="card-body"
              style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
              {{item.totalVentasRango | currency : '$' : 'symbol' :'1.0-0'}}
            </div>
            <button type="button" class="btn btn-relief-primary" (click)=mostrarDetalleVentasTotales() rippleEffect>
              <span *ngIf="mostrarDetalleVentasTotal"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-minus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>VER MENOS</span>
              <span *ngIf="!mostrarDetalleVentasTotal"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-plus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg> VER MAS</span>
            </button>
          </div>
          <div class="card" *ngIf="mostrarDetalleVentasTotal">
            <table class="table table-hover-animation">
              <tbody>
                <tr>
                  <th style="margin: 20%;">
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PRESUPUESTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.totalPpto| currency : '$' : 'symbol' :'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    AÑO ANTERIOR
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.totalVentasRangoPasado | currency : '$' :
                    'symbol':'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CUMPLIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.pTotal | number:'1.0-0'}}%<span *ngIf="colorPt"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg></span>
                    <span *ngIf="flecharojaPt" style="color: rgb(158, 47, 47);"><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CRECIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cTotal | number:'1.0-0'}}%<span *ngIf="colorCf"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-trending-up">
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                        <polyline points="17 6 23 6 23 12"></polyline>
                      </svg></span>
                    <span *ngIf="flecharojacfis" style="color: rgb(158, 47, 47);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-trending-down">
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                        <polyline points="17 18 23 18 23 12"></polyline>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PART ACTUAL
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">100%</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>

                    PENDIENTE
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.pendTotal | currency : '$' : 'symbol' :
                    '1.0-0'}}<span *ngIf="colorPtot" style="color: rgb(49, 170, 79);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg></span>
                    <span *ngIf="flecaRojaPtot" style="color: rgb(158, 47, 47);"><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>

                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-shopping-bag">
                          <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                          <line x1="3" y1="6" x2="21" y2="6"></line>
                          <path d="M16 10a4 4 0 0 1-8 0"></path>
                        </svg>
                      </div>
                    </div>
                    UND TOTALES
                  </th>

                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.tArt | number:'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-credit-card">
                          <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                          <line x1="1" y1="10" x2="23" y2="10"></line>
                        </svg>

                      </div>
                    </div>
                    TRANS TOTALES
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.numeroTransaccionesMes | number:'1.0-0'}}</th>
                </tr>
              </tbody>
            </table>
          </div>


        </div>
      </div>

      <div class="row" *ngIf="mostrarIndicadores">
        <div class="col-12">
          <div class="card " style="background-color:#804ef5">
            <div class="card-header">
              <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                VPT:
              </div>
            </div>
            <div class="divider my-2">
              <div class="divider-text"></div>
            </div>
            <div *ngFor="let item of diar" class="card-body"
              style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
              {{item.vpt | currency : '$' : 'symbol' : '1.0-0'}}
            </div>
            <button type="button" class="btn btn-relief-primary" (click)=mostrarDetallesVPT() rippleEffect>
              <span *ngIf="mostrarDetallesVPTs"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-minus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>VER MENOS</span>
              <span *ngIf="!mostrarDetallesVPTs"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-plus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg> VER MAS</span>
            </button>
          </div>
          <div class="card" *ngIf="mostrarDetallesVPTs">
            <table class="table table-hover-animation">
              <tbody>
                <tr>
                  <th style="margin: 20%;">
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PRESUPUESTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.prptoVPT | currency : '$' : 'symbol' : '1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    AÑO ANTERIOR
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.vptPasado | currency : '$' : 'symbol' : '1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CUMPLIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cumVpt | number:'1.0-0'}}%<span *ngIf="colorPvpt"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>

                      </svg></span>
                    <span *ngIf="flechaRojaPvpt" style="color: rgb(158, 47, 47);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CRECIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cVpt | number:'1.0-0'}}% <span *ngIf="colorCvpt"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-trending-up">
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                        <polyline points="17 6 23 6 23 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaCvpt" style="color: rgb(158, 47, 47);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-trending-down">
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                        <polyline points="17 18 23 18 23 12"></polyline>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
              </tbody>
            </table>
          </div>

          <div class="card " style="background-color:#804ef5">
            <div class="card-header">
              <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                UPT:
              </div>
            </div>
            <div class="divider my-2">
              <div class="divider-text"></div>
            </div>
            <div *ngFor="let item of diar" class="card-body"
              style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
              {{item.upt | number:'1.2-2'}}
            </div>
            <button type="button" class="btn btn-relief-primary" (click)=mostrarDetallesUPT() rippleEffect>
              <span *ngIf="mostrarDetallesUPTs"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-minus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>VER MENOS</span>
              <span *ngIf="!mostrarDetallesUPTs"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-plus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg> VER MAS</span>
            </button>
          </div>
          <div class="card" *ngIf="mostrarDetallesUPTs">
            <table class="table table-hover-animation">
              <tbody>
                <tr>
                  <th style="margin: 20%;">
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PRESUPUESTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.prptoUPT | number:'1.2-2'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    AÑO ANTERIOR
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.uptPasado | number:'1.2-2'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CUMPLIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cumUpt | number:'1.0-0'}}%<span *ngIf="colorPupt"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaPupt" style="color: rgb(158, 47, 47);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CRECIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cUpt | number:'1.0-0'}}%<span *ngIf="colorCupt"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-trending-up">
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                        <polyline points="17 6 23 6 23 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaCupt" style="color: rgb(158, 47, 47);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-trending-down">
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                        <polyline points="17 18 23 18 23 12"></polyline>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
              </tbody>
            </table>
          </div>


          <div class="card " style="background-color:#804ef5">
            <div class="card-header">
              <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                PP:
              </div>
            </div>
            <div class="divider my-2">
              <div class="divider-text"></div>
            </div>
            <div *ngFor="let item of diar" class="card-body"
              style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
              {{item.pP | number:'1.0-0'}}
            </div>
            <button type="button" class="btn btn-relief-primary" (click)=mostrarDetallesPP() rippleEffect>
              <span *ngIf="mostrarDetallesPPs"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-minus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>VER MENOS</span>
              <span *ngIf="!mostrarDetallesPPs"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-plus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg> VER MAS</span>
            </button>
          </div>
          <div class="card" *ngIf="mostrarDetallesPPs">
            <table class="table table-hover-animation">
              <tbody>
                <tr>
                  <th style="margin: 20%;">
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PRESUPUESTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.prptoPP | number:'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    AÑO ANTERIOR
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.pPPasado | number:'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CUMPLIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cumPp | number:'1.0-0'}}%<span *ngIf="colorPpp"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaPpp" style="color: rgb(158, 47, 47);"><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CRECIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cPp | number:'1.0-0'}}%<span *ngIf="colorCpp"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-trending-up">
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                        <polyline points="17 6 23 6 23 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaCpp" style="color: rgb(158, 47, 47);"><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" class="feather feather-trending-down">
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                        <polyline points="17 18 23 18 23 12"></polyline>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
              </tbody>
            </table>
          </div>

          <div class="card " style="background-color:#804ef5">
            <div class="card-header">
              <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                TC:
              </div>
            </div>
            <div class="divider my-2">
              <div class="divider-text"></div>
            </div>
            <div *ngFor="let item of diar" class="card-body"
              style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
              {{item.tC | number:'1.0-0'}}%
            </div>
            <button type="button" class="btn btn-relief-primary" (click)=mostrarDetallesTC() rippleEffect>
              <span *ngIf="mostrarDetallesTCs"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-minus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>VER MENOS</span>
              <span *ngIf="!mostrarDetallesTCs"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-plus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg> VER MAS</span>
            </button>
          </div>
          <div class="card" *ngIf="mostrarDetallesTCs">
            <table class="table table-hover-animation">
              <tbody>
                <tr>
                  <th style="margin: 20%;">
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PRESUPUESTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.prptoTc | number:'1.0-0'}}%</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    AÑO ANTERIOR
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.tCPasado | number:'1.0-0'}}%</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CUMPLIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cumTc | number:'1.0-0'}}% <span *ngIf="colorPtc"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaPtc" style="color: rgb(158, 47, 47);"><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CRECIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cTc | number:'1.0-0'}}%<span *ngIf="colorCtc"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-trending-up">
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                        <polyline points="17 6 23 6 23 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaCtc" style="color: rgb(158, 47, 47);"><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" class="feather feather-trending-down">
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                        <polyline points="17 18 23 18 23 12"></polyline>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
              </tbody>
            </table>
          </div>


          <div class="card " style="background-color:#804ef5">
            <div class="card-header">
              <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                ENTRADAS:
              </div>
            </div>
            <div class="divider my-2">
              <div class="divider-text"></div>
            </div>
            <div *ngFor="let item of diar" class="card-body"
              style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
              {{item.numeroEntradasMes | number:'1.0-0'}}
            </div>
            <button type="button" class="btn btn-relief-primary" (click)=mostrarDetallesEntradas() rippleEffect>
              <span *ngIf="mostrarDetallesEntrada"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-minus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>VER MENOS</span>
              <span *ngIf="!mostrarDetallesEntrada"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-plus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg> VER MAS</span>
            </button>
          </div>
          <div class="card" *ngIf="mostrarDetallesEntrada">
            <table class="table table-hover-animation">
              <tbody>
                <tr>
                  <th style="margin: 20%;">
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PRESUPUESTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.PptoEntradas | number:'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    AÑO ANTERIOR
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.numeroEntradasMesPasado | number:'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CUMPLIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cumEntradas | number:'1.0-0'}}%<span *ngIf="colorPent"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaPent" style="color: rgb(158, 47, 47);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CRECIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cEntradas | number:'1.0-0'}}% <span *ngIf="colorCent"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-trending-up">
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                        <polyline points="17 6 23 6 23 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaCent" style="color: rgb(158, 47, 47);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-trending-down">
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                        <polyline points="17 18 23 18 23 12"></polyline>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
              </tbody>
            </table>
          </div>


          <!-- INICIO PRUEBAS
<td *ngFor="let item of diar">{{item.numeroTransaccionesMes | number:'1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.PptoTrans | number:'1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.numeroTransaccionesMesPasado | number:'1.0-0'}}</td>
                            <td *ngFor="let item of diar" [ngClass]="colorTrans">{{item.cumTrans | number:'1.0-0'}}%
                              <span *ngIf="colorTrans" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaTra" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" [ngClass]="colorCtc">{{item.cTrans | number:'1.0-0'}}%
                              <span *ngIf="colorcTrans" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-up">
                                  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                  <polyline points="17 6 23 6 23 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojacTrans" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-down">
                                  <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                  <polyline points="17 18 23 18 23 12"></polyline>
                                </svg></span>
                            </td>
FIN PRUEBAS -->











          <div class="card " style="background-color:#804ef5">
            <div class="card-header">
              <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                TRANSACCIONES:
              </div>
            </div>
            <div class="divider my-2">
              <div class="divider-text"></div>
            </div>
            <div *ngFor="let item of diar" class="card-body"
              style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
              {{item.numeroTransaccionesMes | number:'1.0-0'}}
            </div>
            <button type="button" class="btn btn-relief-primary" (click)=mostrarDetalleTransaccion() rippleEffect>
              <span *ngIf="mostrarDetalleTransacciones"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-minus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>VER MENOS</span>
              <span *ngIf="!mostrarDetalleTransacciones"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-plus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg> VER MAS</span>
            </button>
          </div>
          <div class="card" *ngIf="mostrarDetalleTransacciones">
            <table class="table table-hover-animation">
              <tbody>
                <tr>
                  <th style="margin: 20%;">
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PRESUPUESTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.PptoTrans | number:'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    AÑO ANTERIOR
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.numeroTransaccionesMesPasado | number:'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CUMPLIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cumTrans | number:'1.0-0'}}%<span *ngIf="colorTrans"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaTra" style="color: rgb(158, 47, 47);"><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CRECIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cTrans | number:'1.0-0'}}% <span *ngIf="colorcTrans"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-trending-up">
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                        <polyline points="17 6 23 6 23 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojacTrans" style="color: rgb(158, 47, 47);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-trending-down">
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                        <polyline points="17 18 23 18 23 12"></polyline>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
              </tbody>
            </table>
          </div>
          <div class="card " style="background-color:#804ef5">
            <div class="card-header">
              <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                CREDITO $:
              </div>
            </div>
            <div class="divider my-2">
              <div class="divider-text"></div>
            </div>
            <div *ngFor="let item of diar" class="card-body"
              style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
              {{item.totalCreditoKaiowa | currency : '$' : 'symbol' :'1.0-0'}}
            </div>
            <button type="button" class="btn btn-relief-primary" (click)=mostrarDetalleVentasCredito() rippleEffect>
              <span *ngIf="mostrarDetalleVentasCreditos"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-minus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>VER MENOS</span>
              <span *ngIf="!mostrarDetalleVentasCreditos"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-plus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg> VER MAS</span>
            </button>
          </div>
          <div class="card" *ngIf="mostrarDetalleVentasCreditos">
            <table class="table table-hover-animation">
              <tbody>
                <tr>
                  <th style="margin: 20%;">
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PRESUPUESTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.presupuestoKaiowa| currency : '$' : 'symbol' :'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    AÑO ANTERIOR
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.totalCreditoKaiowaPasado | currency : '$' :
                    'symbol':'1.0-0'}}</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CUMPLIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cumplimientoKaiowa | number:'1.0-0'}}%<span *ngIf="colorPkai"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaPkai" style="color: rgb(158, 47, 47);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    CRECIMIENTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.cKaiowa | number:'1.0-0'}}% <span *ngIf="colorCkai"
                      style="color: rgb(49, 170, 79);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-trending-up">
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                        <polyline points="17 6 23 6 23 12"></polyline>
                      </svg></span>
                    <span *ngIf="flechaRojaCkai" style="color: rgb(158, 47, 47);"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-trending-down">
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                        <polyline points="17 18 23 18 23 12"></polyline>
                      </svg></span>
                  </th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
              </tbody>
            </table>
          </div>

          <div class="card " style="background-color:#804ef5">
            <div class="card-header">
              <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                PART CREDITO:
              </div>
            </div>
            <div class="divider my-2">
              <div class="divider-text"></div>
            </div>
            <div *ngFor="let item of diar" class="card-body"
              style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
              {{item.partCred | number:'1.1-1'}}%
            </div>
            <button type="button" class="btn btn-relief-primary" (click)=mostrarDetallePartCredito() rippleEffect>
              <span *ngIf="mostrarDetallePartCreditos"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-minus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>VER MENOS</span>
              <span *ngIf="!mostrarDetallePartCreditos"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-plus-square">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg> VER MAS</span>
            </button>
          </div>
          <div class="card" *ngIf="mostrarDetallePartCreditos">
            <table class="table table-hover-animation">
              <tbody>
                <tr>
                  <th style="margin: 20%;">
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PART PRESUPUESTO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.partCredPpto | number:'1.1-1'}}%</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
                <tr>
                  <th>
                    <div class="avatar rounded">
                      <div class="avatar-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>

                      </div>
                    </div>
                    PART CRED PASADO
                  </th>
                  <th>:</th>
                  <th *ngFor="let item of diar">{{item.partCredpasado | number:'1.1-1'}}%</th>
                </tr>
                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>

                <div class="divider my-2">
                  <div class="divider-text"></div>
                </div>
              </tbody>
            </table>
          </div>

        </div>


      </div>


      <!-- <div class="row" *ngIf="mostrarPotenciales">
        <div class="col-12">
          <div class="card " style="background-color:#804ef5">
            <div class="card-header">
              <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                TRANSACCIONES POTENCIALES ADICIONALES (ACUM):
              </div>
            </div>
            <div class="divider my-2">
              <div class="divider-text"></div>
            </div>
            <div *ngFor="let item of diar" class="card-body"
              style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
              {{item.trans | number:'1.0-0'}}
            </div>
          </div>
          <div class="card " style="background-color:#804ef5">
            <div class="card-header">
              <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                TRANSACCIONES POTENCIALES ADICIONALES (DIARIO):
              </div>
            </div>
            <div class="divider my-2">
              <div class="divider-text"></div>
            </div>
            <div *ngFor="let item of diar" class="card-body"
              style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
              {{item.transDiario | number:'1.0-0'}}
            </div>
          </div>
          <div class="card " style="background-color:#804ef5">
            <div class="card-header">
              <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                POTENCIAL EN $ (ACUM):
              </div>
            </div>
            <div class="divider my-2">
              <div class="divider-text"></div>
            </div>
            <div *ngFor="let item of diar" class="card-body"
              style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
              {{item.potTotal | currency : '$' : 'symbol' : '1.0-0' }}
            </div>
          </div>
        </div>
      </div> -->
      <!-- TERMINA CARDS MOBILE -->


      <div class="row sdiv">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="col-lg-12 col-12">
                <div class="card card-company-table">
                  <div class="card-body p-0">
                    <div class="table-responsive">
                      <table class="table table-hover-animation">
                        <thead>
                          <tr>
                            <th>Indicadores</th>
                            <th>Actual</th>
                            <th>Presupuesto</th>
                            <th>Anterior</th>
                            <th>Cumplimiento</th>
                            <th>Crecimiento</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder">VPT</div>
                                  <div class="font-small-2 text-muted">Valor Promedio de la Transacción
                                    =Ventas/Transacciones</div>
                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.vpt | currency : '$' : 'symbol' : '1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.prptoVPT | currency : '$' : 'symbol' : '1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.vptPasado | currency : '$' : 'symbol' : '1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.cumVpt | number:'1.0-0'}}%
                              <span *ngIf="colorPvpt" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>

                                </svg></span>
                              <span *ngIf="flechaRojaPvpt" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar">{{item.cVpt | number:'1.0-0'}}%
                              <span *ngIf="colorCvpt" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-up">
                                  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                  <polyline points="17 6 23 6 23 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaCvpt" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-down">
                                  <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                  <polyline points="17 18 23 18 23 12"></polyline>
                                </svg></span>
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder">UPT</div>
                                  <div class="font-small-2 text-muted">Unidades Promedio por
                                    Transacción=Unidades/Transacciones</div>
                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.upt | number:'1.2-2'}}</td>
                            <td *ngFor="let item of diar">{{item.prptoUPT | number:'1.2-2'}}</td>
                            <td *ngFor="let item of diar">{{item.uptPasado | number:'1.2-2'}}</td>
                            <td *ngFor="let item of diar">{{item.cumUpt | number:'1.0-0'}}%
                              <span *ngIf="colorPupt" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaPupt" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar">{{item.cUpt | number:'1.0-0'}}%
                              <span *ngIf="colorCupt" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-up">
                                  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                  <polyline points="17 6 23 6 23 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaCupt" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-down">
                                  <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                  <polyline points="17 18 23 18 23 12"></polyline>
                                </svg></span>
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder">PP</div>
                                  <div class="font-small-2 text-muted">Precio Promedio=Ventas/Unidades</div>
                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.pP | number:'1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.prptoPP | number:'1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.pPPasado | number:'1.0-0'}}</td>
                            <td *ngFor="let item of diar" [ngClass]="colorPpp">{{item.cumPp | number:'1.0-0'}}%
                              <span *ngIf="colorPpp" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaPpp" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" [ngClass]="colorCpp">{{item.cPp | number:'1.0-0'}}%
                              <span *ngIf="colorCpp" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-up">
                                  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                  <polyline points="17 6 23 6 23 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaCpp" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-down">
                                  <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                  <polyline points="17 18 23 18 23 12"></polyline>
                                </svg></span>
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder">TC</div>
                                  <div class="font-small-2 text-muted">Tasa de Conversión=Transacciones/Entradas</div>
                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.tC | number:'1.1-1'}}%</td>
                            <td *ngFor="let item of diar">{{item.prptoTc | number:'1.1-1'}}%</td>
                            <td *ngFor="let item of diar">{{item.tCPasado | number:'1.1-1'}}%</td>
                            <td *ngFor="let item of diar" [ngClass]="colorPtc">{{item.cumTc | number:'1.0-0'}}%
                              <span *ngIf="colorPtc" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaPtc" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" [ngClass]="colorCtc">{{item.cTc | number:'1.0-0'}}%
                              <span *ngIf="colorCtc" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-up">
                                  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                  <polyline points="17 6 23 6 23 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaCtc" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-down">
                                  <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                  <polyline points="17 18 23 18 23 12"></polyline>
                                </svg></span>
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder">Entradas</div>
                                  <!-- <div class="font-small-2 text-muted">total ventas</div> -->
                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.numeroEntradasMes | number:'1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.PptoEntradas | number:'1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.numeroEntradasMesPasado | number:'1.0-0'}}</td>
                            <td *ngFor="let item of diar" [ngClass]="colorPent">{{item.cumEntradas | number:'1.0-0'}}%
                              <span *ngIf="colorPent" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaPent" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" [ngClass]="colorCent">{{item.cEntradas | number:'1.0-0'}}%
                              <span *ngIf="colorCent" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-up">
                                  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                  <polyline points="17 6 23 6 23 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaCent" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-down">
                                  <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                  <polyline points="17 18 23 18 23 12"></polyline>
                                </svg></span>
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder"> Privilegios</div>
                                  <!-- <div class="font-small-2 text-muted">total ventas</div> -->
                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.totalPrivilegios}}</td>
                            <td *ngFor="let item of diar">{{item.PptoPrivilegios}}</td>
                            <td *ngFor="let item of diar">{{item.PrivilegiosPasado}}</td>
                            <td *ngFor="let item of diar" [ngClass]="colorPrivi">{{item.cumPrivi | number:'1.0-0'}}%
                              <span *ngIf="colorPrivi" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaPrivi" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" [ngClass]="colorCPrivi">{{item.cPrivi | number:'1.0-0'}}%
                              <span *ngIf="colorCPrivi" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-up">
                                  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                  <polyline points="17 6 23 6 23 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaCPrivi" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-down">
                                  <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                  <polyline points="17 18 23 18 23 12"></polyline>
                                </svg></span>
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder">Transacciones</div>
                                  <!-- <div class="font-small-2 text-muted">total ventas</div> -->
                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.numeroTransaccionesMes | number:'1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.PptoTrans | number:'1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.numeroTransaccionesMesPasado | number:'1.0-0'}}</td>
                            <td *ngFor="let item of diar" [ngClass]="colorTrans">{{item.cumTrans | number:'1.0-0'}}%
                              <span *ngIf="colorTrans" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaTra" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" [ngClass]="colorCtc">{{item.cTrans | number:'1.0-0'}}%
                              <span *ngIf="colorcTrans" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-up">
                                  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                  <polyline points="17 6 23 6 23 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojacTrans" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-down">
                                  <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                  <polyline points="17 18 23 18 23 12"></polyline>
                                </svg></span>
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder">Credito $</div>

                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.totalCreditoKaiowa | currency : '$' : 'symbol' :
                              '1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.presupuestoKaiowa | currency : '$' : 'symbol' :
                              '1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.totalCreditoKaiowaPasado | currency : '$' : 'symbol' :
                              '1.0-0'}}</td>
                            <td *ngFor="let item of diar" [ngClass]="colorPkai">
                              {{item.cumplimientoKaiowa |
                              number:'1.0-0'}}%
                              <span *ngIf="colorPkai" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaPkai" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" [ngClass]="colorCkai">{{item.cKaiowa | number:'1.0-0'}}%
                              <span *ngIf="colorCkai" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-up">
                                  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                  <polyline points="17 6 23 6 23 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaCkai" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-down">
                                  <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                  <polyline points="17 18 23 18 23 12"></polyline>
                                </svg></span>
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder">Part Credito</div>

                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.partCred | number:'1.1-1'}}%</td>
                            <td *ngFor="let item of diar">{{item.partCredPpto | number:'1.1-1'}}%</td>
                            <td *ngFor="let item of diar">{{item.partCredpasado | number:'1.1-1'}}%</td>
                            <td *ngFor="let item of diar"></td>
                            <td *ngFor="let item of diar"></td>


                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <button type="button" class="btn btn-flat-success" (click)="enviarexceindicadores()" rippleEffect><i
                  data-feather='file-text'></i>Excel</button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row sdiv">

        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="col-lg-12 col-12">
                <div class="card card-company-table">
                  <div class="card-body p-0">
                    <div class="table-responsive">
                      <table class="table table-hover-animation">
                        <thead>
                          <tr>
                            <th>Otras variables</th>
                            <th>Actual</th>
                            <th>Presupuesto</th>
                            <th>Anterior</th>
                            <th>Cumplimiento</th>
                            <th>Crecimiento</th>

                          </tr>
                        </thead>
                        <tbody>

                        
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder">Crédito $</div>
                                  
                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.totalCreditoKaiowa | currency : '$' : 'symbol' :
                              '1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.presupuestoKaiowa | currency : '$' : 'symbol' :
                              '1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.totalCreditoKaiowaPasado | currency : '$' : 'symbol' :
                              '1.0-0'}}</td>
                            <td *ngFor="let item of diar" [ngClass]="colorPkai">
                              {{item.cumplimientoKaiowa |
                              number:'1.0-0'}}%
                              <span *ngIf="colorPkai" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-check">
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaPkai" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg></span>
                            </td>
                            <td *ngFor="let item of diar" [ngClass]="colorCkai">{{item.cKaiowa | number:'1.0-0'}}%
                              <span *ngIf="colorCkai" style="color: rgb(49, 170, 79);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-up">
                                  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                  <polyline points="17 6 23 6 23 12"></polyline>
                                </svg></span>
                              <span *ngIf="flechaRojaCkai" style="color: rgb(158, 47, 47);"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-trending-down">
                                  <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                  <polyline points="17 18 23 18 23 12"></polyline>
                                </svg></span>
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder">Part Crédito/Venta total</div>
                                  
                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.partCred | number:'1.1-1'}}%</td>
                            <td *ngFor="let item of diar">{{item.partCredPpto | number:'1.1-1'}}%</td>
                            <td *ngFor="let item of diar">{{item.partCredpasado | number:'1.1-1'}}%</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder"># Crédito</div>
                                  
                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.cantidadTransaccionesCreditos | number:'1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.PptoCreditoNro | number:'1.1-1'}}</td>
                            <td *ngFor="let item of diar">{{item.cantidadTransaccionesCreditosPasado | number:'1.0-0'}}</td>
                            <td *ngFor="let item of diar">{{item.cumNumCred | number:'1.0-0'}}%
                            <span *ngIf="colorTranskai" style="color: rgb(49, 170, 79);"><svg
                              xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-check">
                              <polyline points="20 6 9 17 4 12"></polyline>
                            </svg></span>
                          <span *ngIf="flechaRojaTranskai" style="color: rgb(158, 47, 47);"><svg
                              xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-x">
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg></span></td>
                            <td *ngFor="let item of diar">{{item.crecNumCred | number:'1.0-0'}}%
                              <span *ngIf="colorTransCred" style="color: rgb(49, 170, 79);"><svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-trending-up">
                                <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                <polyline points="17 6 23 6 23 12"></polyline>
                              </svg></span>
                            <span *ngIf="flechaRojaTransCred" style="color: rgb(158, 47, 47);"><svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-trending-down">
                                <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                <polyline points="17 18 23 18 23 12"></polyline>
                              </svg></span>
                            
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder">Part # Crédito/Trans totales</div>
                                  
                                </div>
                              </div>
                            </td>
                            <td *ngFor="let item of diar">{{item.partTransCred | number:'1.1-1'}}%

                            </td>
                            <td *ngFor="let item of diar">{{item.partPresCred | number:'1.1-1'}}%

                            </td>
                            <td *ngFor="let item of diar">{{item.partNumCredPasado | number:'1.1-1'}}%
                              
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder">%Cédulas Válidas</div>
                                  
                                </div>
                              </div>
                            </td>
                            <td><span *ngFor="let item of diar">{{item.cantidadCedulasValidas}}%</span></td>
                            <td>0.0%</td>
                            <td><span *ngFor="let item of diar">{{item.cantidadCedulasValidasPasado}}%</span></td>
                            <td>0.0%</td>
                            <td><span *ngFor="let item of diar">{{item.crecCantCedValid | number:'1.0-0'}}%</span>
                              <span *ngIf="colorcrecCantCedValid" style="color: rgb(49, 170, 79);"><svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-trending-up">
                                <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                <polyline points="17 6 23 6 23 12"></polyline>
                              </svg></span>
                            <span *ngIf="flechaRojacrecCantCedValid" style="color: rgb(158, 47, 47);"><svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-trending-down">
                                <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                <polyline points="17 18 23 18 23 12"></polyline>
                              </svg></span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">

                                <div>
                                  <div class="font-weight-bolder">%Cédulas Comodín(no válidas)</div>
                                  
                                </div>
                              </div>
                            </td>
                            <td><span *ngFor="let item of diar">{{item.cedulasComodin}}%</span></td>
                            <td>0.0%</td>
                            <td><span *ngFor="let item of diar">{{item.cedulasComodinPasado}}%</span></td>
                            <td>0.0%</td>
                            <td><span *ngFor="let item of diar">{{item.cedulasCrecim | number:'1.0-0'}}%</span>
                              <span *ngIf="flecharojaCcrecim" style="color: rgb(158, 47, 47);"><svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-trending-up">
                                <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                <polyline points="17 6 23 6 23 12"></polyline>
                              </svg></span>
                            <span *ngIf="colorCcrecim" style="color: rgb(49, 170, 79);"><svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-trending-down">
                                <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                                <polyline points="17 18 23 18 23 12"></polyline>
                              </svg></span>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                </div>
                <button type="button" class="btn btn-flat-success" (click)="enviarexceotrasvariables()" rippleEffect><i
                  data-feather='file-text'></i>Excel</button>
             
              </div>
            </div>
         
          </div>
        </div>
      </div> -->
      <!-- <div class="row sdiv">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="col-lg-12 col-12">
                <div class="card card-company-table">
                  <div class="card-body p-0">
                    <div class="table-responsive">
                      <table class="table table-hover-animation">
                        <thead>
                          <tr>
                            <th>Potenciales</th>
                            <th></th>
                            <th></th>



                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Transacciones Potenciales Adicionales (ACUM)</th>
                            <td *ngFor="let item of diar" [ngClass]="colorTransss">{{item.trans | number:'1.0-0'}}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>Transacciones Potenciales Adicionales (DIARIO)</th>
                            <td *ngFor="let item of diar" [ngClass]="colorTransDia">{{item.transDiario |
                              number:'1.0-0'}}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <th><abbr title="TRANS POTENCIALES ADIC X VPT PPTO">Potencial en $ (ACUM)</abbr></th>
                            <td *ngFor="let item of diar" [ngClass]="colorTransTotal  ">{{item.potTotal | currency : '$'
                              : 'symbol' : '1.0-0' }}</td>
                            <td></td>
                          </tr>



                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-flat-success" (click)="enviarexcepotenciales()" rippleEffect><i
                  data-feather='file-text'></i>Excel</button>
            </div>
          </div>
        </div>
      </div> -->



    </section>


  </div>
</div>