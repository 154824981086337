<div class="content-wrapper container-xxl p-0">

    <div class="content-body">
        <!-- content-header component -->

        <app-content-header [contentHeader]="contentHeader">

        </app-content-header>

        <!-- Basic Alerts start -->
        <!-- <core-theme-customizer></core-theme-customizer> -->
        <section id="home-page">
            <section class="range-selection-DP">

                <div class="row">
                    <div class="col-12">
                        <div class="card">

                            <div class="card-body">

                                <!-- Range selection Date Picker -->
                                <div class="card">
                                    <div class="row">
                                        <div class="col-12">
                                            <span style="font-weight: bolder;">Fechas</span>
                                            <div class="row" style="margin-left:1rem;">
                                                <form class="form-inline">
                                                    <div class="form-group">
                                                        <div class="input-group w-0 ">
                                                            <input name="datepicker" class="form-control" ngbDatepicker
                                                                #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                                                [showWeekNumbers]="false"
                                                                (dateSelect)="onDateSelection($event,datepicker,modalBasic)"
                                                                [displayMonths]="1" [dayTemplate]="t"
                                                                outsideDays="visible" [startDate]="fromDate!"
                                                                tabindex="-1" />
                                                            <ng-template #t let-date let-currentMonth="currentMonth"
                                                                let-selected="selected" let-focused="focused">
                                                                <span class="custom-day" [class.focused]="focused"
                                                                    [class.hidden]="date.month !== currentMonth"
                                                                    [class.text-muted]="disabled"
                                                                    [class.range]="isRange(date)"
                                                                    [class.faded]="isHovered(date) || isInside(date)"
                                                                    (mouseenter)="hoveredDate = date"
                                                                    (mouseleave)="hoveredDate = null">
                                                                    {{ date.day }}


                                                                </span>

                                                            </ng-template>

                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input style="display: none;" #dpFromDate
                                                                class="form-control" placeholder="yyyy-mm-dd"
                                                                name="dpFromDate" [value]="formatter.format(fromDate)"
                                                                (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                                                                disabled />
                                                            <div class="input-group fecha">
                                                                <input class="fecha" type="text" [value]="desdehasta"
                                                                    name="" id="" disabled>
                                                            </div>
                                                            <div class="input-group-append">
                                                                <button
                                                                    class="btn btn-outline-secondary feather icon-calendar"
                                                                    (click)="datepicker.toggle()" type="button"
                                                                    rippleEffect></button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ng-template #modalBasic let-modal>
                                                        <div class="modal-header">
                                                            <h4 class="modal-title" id="myModalLabel1"><i
                                                                    data-feather='arrow-left'></i></h4>

                                                        </div>
                                                        <div class="modal-body" tabindex="0" ngbAutofocus>

                                                            <p>
                                                                Seleccione la fecha de inicio y la fecha fin en el mismo
                                                                calendario
                                                            </p>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-primary"
                                                                (click)="modal.close('Accept click')" rippleEffect>
                                                                Aceptar
                                                            </button>
                                                        </div>
                                                    </ng-template>
                                                    <div class="form-group ml-sm-2" style="display: none;">
                                                        <div class="input-group">
                                                            <input #dpToDate class="form-control"
                                                                placeholder="yyyy-mm-dd" name="dpToDate"
                                                                [value]="formatter.format(toDate)" disabled />
                                                            <div class="input-group-append">
                                                                <button class="btn btn-outline-secondary feather "
                                                                    (click)="datepicker.toggle()" type="button"
                                                                    rippleEffect disabled></button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </form>



                                                <div class="row">
                                                    <div class="columnbut">
                                                        <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                            (click)="seleccionarAnterior()" rippleEffect>
                                                            Seleccionar Mes Anterior
                                                        </button>
                                                    </div>
                                                    <div class="columnbut">
                                                        <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                            (click)="seleccionarMesActual()" rippleEffect>
                                                            Seleccionar Mes Actual
                                                        </button>
                                                    </div>
                                                    <div class="columnbut">
                                                        <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                            (click)="seleccionarHoy()" rippleEffect>
                                                            Seleccionar Hoy</button>
                                                    </div>

                                                    <div class="columnbut">

                                                        <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                            (click)="seleccionarAyer()" rippleEffect>
                                                            Seleccionar Ayer</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column" style="width:40% " *ngIf="banderaFiltro">
                                            <div class="row">
                                                <span style="font-weight: bolder;">Punto Venta</span>
                                                <div *ngIf="mostrarfiltros" class="columy"
                                                    style="padding-left: unset;padding-right: unset;">


                                                    <ng-select [clearSearchOnAdd]="true" [items]="tiendas"
                                                        [clearable]="false" [multiple]="true" [closeOnSelect]="false"
                                                        [searchable]="true" bindLabel="Nombre"
                                                        placeholder="Seleccione PV" [(ngModel)]="codigoTienda">
                                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                            <span class="ng-value-label" [value]="item.Codigo"> {{
                                                                item.Nombre }}</span>
                                                            <span class="ng-value-icon right" (click)="clear(item)"
                                                                aria-hidden="true">×</span>
                                                        </ng-template>
                                                    </ng-select>


                                                </div>
                                                <div class="columx" style="padding-left: unset;padding-right: unset;">
                                                    <button type="button" class="btn btn-flat-danger" rippleEffect
                                                        (click)="limpiarFiltPv()" title="limpiar filtro">X</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <button [disabled]="loading" (click)="buscar()" class="btn btn-relief-primary"
                                        tabindex="4" rippleEffect>
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>Consultar
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div class="container mt-4">
                            <div class="row">
                                <!-- Tarjeta Total Acumulado -->
                                <div class="col-md-6">
                                    <div class="card shadow-sm">
                                        <div class="card-header bg-primary text-white text-center">
                                            <h4><b>Total Acumulado</b></h4>
                                        </div>
                                        <div class="card-body">
                                            <table class="table table-striped text-center">
                                                <thead class="table-dark text-dark">
                                                    <tr>
                                                        <th>Ventas</th>
                                                        <th>Actual</th>
                                                        <th>Unidades</th>
                                                        <th>Transacciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><b>Físicas</b></td>
                                                        <td>{{ventas?.TotalVentasFisicas | currency}}</td>
                                                        <td>{{ventas?.TotalUnidadesFisicas}}</td>
                                                        <td>{{ventas?.TotalTransaccionesFisicas}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Digitales</b></td>
                                                        <td>{{ventas?.TotalVentasDigitales | currency}}</td>
                                                        <td>{{ventas?.TotalUnidadesDigitales}}</td>
                                                        <td>{{ventas?.TotalTransaccionesDigitales}}</td>
                                                    </tr>
                                                    <tr class="table-success">
                                                        <td><b>Totales</b></td>
                                                        <td>{{ (ventas?.TotalVentasFisicas + ventas?.TotalVentasDigitales) | currency }}</td>
                                                        <td>{{ ventas?.TotalUnidadesFisicas + ventas?.TotalUnidadesDigitales }}</td>
                                                        <td>{{ ventas?.TotalTransaccionesFisicas + ventas?.TotalTransaccionesDigitales }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                        
                                <!-- Tarjeta Cumplimiento -->
                                <div class="col-md-6">
                                    <div class="card shadow-sm">
                                        <div class="card-header bg-success text-white text-center">
                                            <h4><b>Cumplimiento</b></h4>
                                        </div>
                                        <div class="card-body">
                                            <table class="table table-striped text-center">
                                                <thead class="table-dark text-dark">
                                                    <tr>
                                                        <th>Total Ventas</th>
                                                        <th>Presupuesto</th>
                                                        <th>Cumplimiento</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{{ (ventas?.TotalVentasFisicas + ventas?.TotalVentasDigitales) | currency }}</td>
                                                        <td>{{ (ventas?.PresupuestoTotalFisico + ventas?.PresupuestoTotalDigital) | currency }}</td>
                                                        <td>
                                                            {{ ((ventas?.TotalVentasFisicas + ventas?.TotalVentasDigitales) * 100 / (ventas?.PresupuestoTotalFisico + ventas?.PresupuestoTotalDigital)) | number:'1.0-0' }}%
                                                            <span *ngIf="(ventas?.TotalVentasFisicas + ventas?.TotalVentasDigitales) * 100 / (ventas?.PresupuestoTotalFisico + ventas?.PresupuestoTotalDigital) >= 100">
                                                                ✅
                                                            </span>
                                                            <span *ngIf="(ventas?.TotalVentasFisicas + ventas?.TotalVentasDigitales) * 100 / (ventas?.PresupuestoTotalFisico + ventas?.PresupuestoTotalDigital) < 100">
                                                                🔻
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <!-- Tarjeta Crecimiento -->
                            <div class="card shadow-sm mt-4">
                                <div class="card-header  text-center">
                                    <h4><b>Crecimiento</b></h4>
                                </div>
                                <div class="card-body">
                                    <table class="table table-striped text-center">
                                        <thead class="table-dark text-dark">
                                            <tr>
                                                <th>Variable</th>
                                                <th>Actual</th>
                                                <th>Anterior</th>
                                                <th>Crecimiento</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><b>Ventas</b></td>
                                                <td>{{ (ventas?.TotalVentasFisicas + ventas?.TotalVentasDigitales) | currency }}</td>
                                                <td>{{ (ventas?.TotalVentasFisicas_Anterior + ventas?.TotalVentasDigitales_Anterior) | currency }}</td>
                                                <td>
                                                    {{ ((ventas?.TotalVentasFisicas + ventas?.TotalVentasDigitales) * 100 / (ventas?.TotalVentasFisicas_Anterior + ventas?.TotalVentasDigitales_Anterior)) | number:'1.0-0' }}%
                                                    <span *ngIf="(ventas?.TotalVentasFisicas + ventas?.TotalVentasDigitales) > (ventas?.TotalVentasFisicas_Anterior + ventas?.TotalVentasDigitales_Anterior)">
                                                        📈
                                                    </span>
                                                    <span *ngIf="(ventas?.TotalVentasFisicas + ventas?.TotalVentasDigitales) <= (ventas?.TotalVentasFisicas_Anterior + ventas?.TotalVentasDigitales_Anterior)">
                                                        📉
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Unidades</b></td>
                                                <td>{{ ventas?.TotalUnidadesFisicas + ventas?.TotalUnidadesDigitales }}</td>
                                                <td>{{ ventas?.TotalUnidadesFisicas_Anterior + ventas?.TotalUnidadesDigitales_Anterior }}</td>
                                                <td>
                                                    {{ ((ventas?.TotalUnidadesFisicas + ventas?.TotalUnidadesDigitales) * 100 / (ventas?.TotalUnidadesFisicas_Anterior + ventas?.TotalUnidadesDigitales_Anterior)) | number:'1.0-0' }}%
                                                    <span *ngIf="(ventas?.TotalUnidadesFisicas + ventas?.TotalUnidadesDigitales) > (ventas?.TotalUnidadesFisicas_Anterior + ventas?.TotalUnidadesDigitales_Anterior)">
                                                        📈
                                                    </span>
                                                    <span *ngIf="(ventas?.TotalUnidadesFisicas + ventas?.TotalUnidadesDigitales) <= (ventas?.TotalUnidadesFisicas_Anterior + ventas?.TotalUnidadesDigitales_Anterior)">
                                                        📉
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Transacciones</b></td>
                                                <td>{{ ventas?.TotalTransaccionesFisicas + ventas?.TotalTransaccionesDigitales }}</td>
                                                <td>{{ ventas?.TotalTransaccionesFisicas_Anterior + ventas?.TotalTransaccionesDigitales_Anterior }}</td>
                                                <td>
                                                    {{ ((ventas?.TotalTransaccionesFisicas + ventas?.TotalTransaccionesDigitales) * 100 / (ventas?.TotalTransaccionesFisicas_Anterior + ventas?.TotalTransaccionesDigitales_Anterior)) | number:'1.0-0' }}%
                                                    <span *ngIf="(ventas?.TotalTransaccionesFisicas + ventas?.TotalTransaccionesDigitales) > (ventas?.TotalTransaccionesFisicas_Anterior + ventas?.TotalTransaccionesDigitales_Anterior)">
                                                        📈
                                                    </span>
                                                    <span *ngIf="(ventas?.TotalTransaccionesFisicas + ventas?.TotalTransaccionesDigitales) <= (ventas?.TotalTransaccionesFisicas_Anterior + ventas?.TotalTransaccionesDigitales_Anterior)">
                                                        📉
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                        
                        

                    </div>
                </div>
            </section>
        </section>
    </div>
</div>